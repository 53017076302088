import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage/session'

import appReducer from './features/app/appSlice';
import questionsReducer from './features/questions/questionsSlice';

const appPersistConfig = {
  key: 'app',
  version: 1,
  storage,
  blacklist: ['currentStepIndex'],
};

const questionsPersistConfig = {
  key: 'questions',
  version: 1,
  storage,
};

const rootReducer = combineReducers({
  app: persistReducer(appPersistConfig, appReducer),
  questions: persistReducer(questionsPersistConfig, questionsReducer),
});
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
