import { Question } from '../../features/questions/questionsData';

export const otherOptionDefaultValue = '##OTHER##';

export const otherOptionPlaceholder = 'Please specify';

export const getQuestionName = (question: Question, index: number): string => {
  return question.id || `question-${index}`;
};

export const getOtherQuestionName = (
  question: Question,
  index: number
): string => {
  return `${getQuestionName(question, index)}-other`;
};

export const answerIsOtherOption = (
  question: Question,
  answer: number | string | string[]
) => {
  if (typeof answer === 'number') return false;

  const options = question.settings.options || [];
  const otherValues = options
    .filter((opt) => opt.specify && (opt.value || opt.text))
    .map((opt) => opt.value || opt.text);
  otherValues.push(otherOptionDefaultValue);

  if (typeof answer === 'string') {
    return otherValues.includes(answer);
  } else if (Array.isArray(answer)) {
    return answer.find((ans) => otherValues.includes(ans));
  }

  return false;
};
