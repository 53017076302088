export type ArrowDirections = 'Left' | 'Right' | 'None';

export interface ButtonProps extends React.PropsWithChildren {
  className?: string;
  arrowDirection?: ArrowDirections;
  variant?: keyof typeof variants;
  disabled?: boolean;
  onClick?: Function;
  link?: string;
}

export interface Variants {
  primary: string;
  secondary: string;
  tertiary: string;
  basic: string;
}

export const variants: Variants = Object.freeze({
  primary:
    'py-4 px-6 bg-green-500 hover:bg-green-600 active:bg-green-700 rounded text-white font-bold transition-colors',
  secondary:
    'py-4 px-6 border border-green-500 hover:border-green-600 active:border-green-700 text-green-500 hover:text-green-600 active:text-green-700 rounded font-bold transition-colors',
  tertiary:
    'py-4 px-6 text-grey-800 hover:text-charcoal active:text-jet font-bold transition-colors',
  basic:
    'py-4 text-green-500 hover:text-green-600 active:text-green-700 font-normal underline transition-colors',
});

export const disabledVariants: Variants = Object.freeze({
  primary: 'py-4 px-6 bg-titanium rounded text-grey-800 font-bold',
  secondary: 'py-4 px-6 bg-titanium rounded text-grey-800 font-bold',
  tertiary: 'py-4 px-6 bg-titanium rounded text-grey-800 font-bold',
  basic: 'py-4 text-titanium',
});
