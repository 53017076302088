// Dependencies
import { FunctionComponent } from 'react';
import { mdiArrowLeft, mdiArrowRight } from '@mdi/js';
import Icon from '@mdi/react';

// Models
import { ButtonProps, disabledVariants, variants } from './Button.model';

export const Button: FunctionComponent<ButtonProps> = ({
  children,
  className,
  arrowDirection = 'None',
  onClick,
  link = '',
  variant = 'primary',
  disabled: softDisabled = false,
}) => {
  if (link) {
    const linkIsExternal =
      link.startsWith('http://') || link.startsWith('https://');
    const externalLinkProps = linkIsExternal
      ? {
          target: '_blank',
          rel: 'noreferrer',
        }
      : {};

    return (
      <a
        className={`flex items-center justify-center text-base leading-4 ${
          softDisabled ? disabledVariants[variant] : variants[variant]
        } ${className ? className : ''}`}
        href={link}
        {...externalLinkProps}
      >
        {arrowDirection === 'Left' && (
          <Icon path={mdiArrowLeft} size="1rem" className="mr-2" />
        )}
        {children}
        {arrowDirection === 'Right' && (
          <Icon path={mdiArrowRight} size="1rem" className="ml-2" />
        )}
      </a>
    );
  } else {
    return (
      <button
        className={`flex items-center justify-center text-base leading-4 ${
          softDisabled ? disabledVariants[variant] : variants[variant]
        } ${className ? className : ''}`}
        onClick={() => onClick && onClick()}
        type={onClick ? 'button' : 'submit'}
      >
        {arrowDirection === 'Left' && (
          <Icon path={mdiArrowLeft} size="1rem" className="mr-2" />
        )}
        {children}
        {arrowDirection === 'Right' && (
          <Icon path={mdiArrowRight} size="1rem" className="ml-2" />
        )}
      </button>
    );
  }
};
