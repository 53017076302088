import { FunctionComponent } from 'react';
import { imageAspectRatioClasses, ImageProps } from './Image.model';

/**
 * Image Component
 * @params props - Required component props.
 */
export const Image: FunctionComponent<ImageProps> = ({
  url,
  width,
  height,
  altText = '',
  aspectRatio = 'fluid',
  priority = false,
}) => {
  // Handle Normal Fluid Images
  if (aspectRatio === 'fluid') {
    return (
      <img
        alt={altText}
        width={width}
        height={height}
        src={url}
        className="h-auto w-full"
        loading={priority ? 'eager' : 'lazy'}
      />
    );
  }

  // auto
  if (aspectRatio === 'auto') {
    return (
      <img
        alt={altText}
        width={width}
        height={height}
        src={url}
        className="mx-auto h-auto w-auto max-w-full"
        loading={priority ? 'eager' : 'lazy'}
      />
    );
  }

  if (aspectRatio === 'absolute') {
    return (
      <img
        alt={altText}
        sizes="100vw"
        src={url}
        className="absolute top-0 left-0 h-full w-full object-cover object-right"
        loading={priority ? 'eager' : 'lazy'}
      />
    );
  }

  return (
    <div className="relative w-full">
      <div className={`w-full ${imageAspectRatioClasses[aspectRatio]}`}></div>
      <img
        alt={altText}
        sizes="100vw"
        src={url}
        className="absolute top-0 left-0 h-full w-full object-cover object-center"
        loading={priority ? 'eager' : 'lazy'}
      />
    </div>
  );
};
