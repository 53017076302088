import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getIn } from 'formik';
import {
  answerIsOtherOption,
  getOtherQuestionName,
  getQuestionName,
} from '../../helpers/questionHelper/questionHelper';

import { shuffle } from '../../helpers/shuffle/shuffle';
import { answerIsValid } from '../../helpers/validationHelper/validationHelper';
import { RootState } from '../../store';
import sortedQuestionsData, { QuestionData } from './questionsData';

export type LoadingStatus = 'idle' | 'loading';

interface QuestionsState {
  steps: QuestionData[];
  status: LoadingStatus;
  error: boolean;
  currentStepIndex: number;
}

const initialState: QuestionsState = {
  steps: sortedQuestionsData,
  status: 'idle',
  error: false,
  currentStepIndex: 0,
};

// Shuffle options for radio lists and checkbox lists
initialState.steps = initialState.steps.map((step) => {
  step.questions = step.questions.map((question) => {
    if (!question.settings.options || !question.settings.randomise)
      return question;

    const optionsToShuffle = question.settings.options.filter(
      (option) => !option.specify
    );
    const otherOptions = question.settings.options.filter(
      (option) => option.specify
    );
    question.settings.options = shuffle(optionsToShuffle).concat(otherOptions);
    return question;
  });
  return step;
});

const questionsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    updateLoading(state, action: PayloadAction<LoadingStatus>) {
      state.status = action.payload;
    },
    updateError(state, action: PayloadAction<boolean>) {
      state.error = action.payload;
    },
    storeAnswerForStep(
      state,
      action: PayloadAction<{ stepIndex: number; answers: any }>
    ) {
      const { stepIndex, answers } = action.payload;

      const currentStep = state.steps[stepIndex];
      currentStep.questions.forEach((question, index) => {
        const questionName = getQuestionName(question, index);
        const questionAnswer = getIn(answers, questionName);
        question.answer = questionAnswer;

        if (answerIsOtherOption(question, questionAnswer)) {
          const otherQuestionName = getOtherQuestionName(question, index);
          question.otherAnswer = getIn(answers, otherQuestionName);
        } else {
          question.otherAnswer = '';
        }
      });
    },
  },
});

export const { updateLoading, updateError, storeAnswerForStep } =
  questionsSlice.actions;

export default questionsSlice.reducer;

// Selectors: Used to select a value from the state.
export const selectCurrentStep = (state: RootState) =>
  state.questions.steps[state.app.currentStepIndex];

export const selectLoadingState = (state: RootState) => state.questions.status;

export const selectErrorState = (state: RootState) => state.questions.error;

export const selectLastCompletedStepIndex = (state: RootState) => {
  const previousSteps = state.questions.steps
    .slice(0, state.app.currentStepIndex)
    .reverse();

  let lastCompletedIndex = 0;
  for (let i = 0; i < previousSteps.length; i++) {
    let step = previousSteps[i];
    if (
      !step.questions.find((question) => {
        return !answerIsValid(question.answer);
      })
    ) {
      lastCompletedIndex = state.app.currentStepIndex - 1 - i;
      break;
    }
  }

  return lastCompletedIndex;
};
