import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import sortedQuestionsData from '../questions/questionsData';

// There are sub-steps which have the same step number, so the number of steps is not the number of items in the steps array
const lastStepNumber = sortedQuestionsData
  .map((step) => step.number)
  .reverse()[0];

interface AppState {
  currentStepIndex: number;
  availableSteps: number;
  userReferenceId: string;
  surveyStarted: boolean;
  source: string;
}

function getRequestedStepNumber() {
  let stepNumber = 1;
  const pathParts = window.location.pathname.split('/');
  if (pathParts.length >= 3 && pathParts[1] === 'steps') {
    stepNumber = Number(pathParts[2]);
  }

  return stepNumber || 1;
}

const initialState: AppState = {
  currentStepIndex: sortedQuestionsData.findIndex(
    (step) => step.number === getRequestedStepNumber()
  ),
  availableSteps: lastStepNumber,
  userReferenceId: '',
  surveyStarted: false,
  source: '',
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setStepIndex(state, action: PayloadAction<number>) {
      state.currentStepIndex = action.payload;
    },
    setUserReferenceId(state, action: PayloadAction<string>) {
      state.userReferenceId = action.payload;
    },
    setSurveyStarted(state, action: PayloadAction<boolean>) {
      state.surveyStarted = action.payload;
    },
    setUserSource(state, action: PayloadAction<string>) {
      state.source = action.payload;
    },
  },
});

export const {
  setStepIndex,
  setUserReferenceId,
  setSurveyStarted,
  setUserSource,
} = appSlice.actions;
export default appSlice.reducer;

// Selectors: Used to select a value from the state.
export const selectCurrentIndex = (state: RootState) =>
  state.app.currentStepIndex;

export const selectAvailableSteps = (state: RootState) =>
  state.app.availableSteps;

export const selectUserReferenceId = (state: RootState) =>
  state.app.userReferenceId;

export const selectSurveyStarted = (state: RootState) =>
  state.app.surveyStarted;

export const selectUserSource = (state: RootState) => state.app.source;
