// Dependencies
import React from 'react';

// Components
import { MainContainer } from './containers/MainContainer/MainContainer';

const App: React.FC<{}> = () => {
  return <MainContainer />;
};

export default App;
